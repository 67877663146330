import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ConversionTrackingEvent } from '../conversion-tracking.service';
import { isPlatformBrowser } from '@angular/common';

interface GoogleEvent {
  action: string;
  properties?: {
    currency?: string;
    value?: number;
    items?: { item_name?: string }[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class GooglePixelService {
  w: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.w = window as any;
    }
  }

  private track(event: GoogleEvent) {
    if (this.w && this.w.gtag) {
      this.w.gtag('event', event.action, event.properties);
    }
  }

  trackLead(event: Partial<ConversionTrackingEvent>) {
    this.track({
      action: 'generate_lead',
    });
  }

  trackViewContent(event: Partial<ConversionTrackingEvent>) {
    this.track({
      action: 'view_item',
      properties: {
        currency: event.currency,
        value: event.price,
        items: [{ item_name: event.items?.[0].name }],
      },
    });
  }

  trackCompleteRegistration(event: Partial<ConversionTrackingEvent>) {
    this.track({
      action: 'sign_up',
    });
  }

  trackAddToCart(event: Partial<ConversionTrackingEvent>) {
    this.track({
      action: 'add_to_cart',
      properties: {
        currency: event.currency,
        value: event.price,
        items: [{ item_name: event.items?.[0].name }],
      },
    });
  }

  trackInitiateCheckout(event: Partial<ConversionTrackingEvent>) {
    this.track({
      action: 'begin_checkout',
      properties: {
        currency: event.currency,
        value: event.price,
        items: event.items?.map((item) => ({
          item_name: item.name,
        })),
      },
    });
  }

  trackPurchase(event: Partial<ConversionTrackingEvent>) {
    this.track({
      action: 'purchase',
      properties: {
        currency: event.currency,
        value: event.price,
        items: event.items?.map((item) => ({
          item_name: item.name,
        })),
      },
    });
  }

  trackNewsletterSubscription(event: Partial<ConversionTrackingEvent>) {
    this.track({
      action: 'newsletter_subscribe',
    });
  }
}
